import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import get from 'lodash/get';
import ResizeObserver from 'resize-observer-polyfill';

import cssVars from 'css-vars-ponyfill';
import dom from './wrapper/DomWrapper';
import SharingButtons from './implementation/SharingButtons';
import Gallery from './implementation/Gallery';
import Photo from './implementation/Photo';
import EmbedCode from './implementation/EmbedCode';
import Header from './implementation/Header';
import Instagram from './implementation/Instagram';
import Video from './implementation/Video';
import Form from './implementation/Form';
import Slider from './implementation/Slider';
import Twitter from './implementation/Twitter';
import SVGLoader from './implementation/SVGLoader';
import CookieBanner from './implementation/CookieBannerManager';
import BlogSocialButtons from './implementation/Blog/SocialButtons/SocialButtons';
import imageErrorHandler from './implementation/Image';
import BackToTop from './implementation/BackToTop';
import PostImage from './implementation/PostImage';
import ItemsView from './implementation/ItemsView';
import initAnalytics from './implementation/Analytics';
import Promotion from './implementation/Promotion';
import initPremiumFeatures from './implementation/PremiumFeatures';
import Facebook from './implementation/Facebook';
import CartButton from './implementation/CartButton';
import SocialButtons from './implementation/SocialButtons';
import ProductsWidget from './implementation/Ecommerce/ecwid/custom/ProductsWidget';
import { connectRouter } from './ecommerce/ecwid/Routers';
import lazyLoad from './implementation/LazyLoad';
import TemplatePreview from './implementation/TemplatePreview';
import handleAnchors from './implementation/Anchor';
import PinterestButton from './implementation/PinterestButton';
import './polyfills';

import { createDeviceObserver, initDeviceObserver } from './observer/deviceObserver';
import { createResizeObserver, initResizeObserver } from './observer/resizeObserver';

import { setBodyColorClasses, setBodyHasScroll } from './helpers/setBodyClasses';
import ieChangeCssVar from './helpers/ieChangeCssVar';
import browser from './helpers/browser';
import getStateValue from './helpers/getStateValue';

import {
  BLOG_SOCIAL_WIDGET_NAME,
  BLOG_SOCIAL_WIDGET_SELECTOR,
  CART_BUTTON_NAME,
  CART_BUTTON_SELECTOR,
  COOKIE_NOTICE_NAME,
  COOKIE_NOTICE_SELECTOR,
  ECOM_PRODUCT_NAME,
  ECOM_PRODUCT_SELECTOR,
  EMBED_CODE_WIDGET_NAME,
  EMBED_CODE_WIDGET_SELECTOR,
  FACEBOOK_NAME,
  FACEBOOK_SELECTOR,
  FORM_WIDGET_NAME,
  FORM_WIDGET_SELECTOR,
  GALLERY_WIDGET_NAME,
  GALLERY_WIDGET_SELECTOR,
  HEADER_WIDGET_NAME,
  HEADER_WIDGET_SELECTOR,
  INSTAGRAM_WIDGET_NAME,
  INSTAGRAM_WIDGET_SELECTOR,
  ITEMS_VIEW_NAME,
  ITEMS_VIEW_SELECTOR,
  PHOTO_CODE_WIDGET_NAME,
  PHOTO_CODE_WIDGET_SELECTOR,
  POST_IMAGE_NAME,
  POST_IMAGE_SELECTOR,
  PROMOTION_NAME,
  PROMOTION_SELECTOR,
  SHARING_BUTTONS_WIDGET_NAME,
  SHARING_BUTTONS_WIDGET_SELECTOR,
  SLIDER_WIDGET_NAME,
  SLIDER_WIDGET_SELECTOR, SOCIAL_BUTTON_NAME, SOCIAL_BUTTON_SELECTOR,
  SVG_LOADER_WIDGET_NAME,
  SVG_LOADER_WIDGET_SELECTOR,
  TWITTER_WIDGET_NAME,
  TWITTER_WIDGET_SELECTOR,
  VIDEO_WIDGET_NAME,
  VIDEO_WIDGET_SELECTOR,
  PINTEREST_BUTTON_NAME,
  PINTEREST_BUTTON_SELECTOR,
} from './constants';

const WIDGETS_MAP = new Map([
  [
    SHARING_BUTTONS_WIDGET_NAME,
    new SharingButtons(SHARING_BUTTONS_WIDGET_SELECTOR),
  ],
  [
    GALLERY_WIDGET_NAME,
    new Gallery(GALLERY_WIDGET_SELECTOR),
  ],
  [
    EMBED_CODE_WIDGET_NAME,
    new EmbedCode(EMBED_CODE_WIDGET_SELECTOR),
  ],
  [
    HEADER_WIDGET_NAME,
    new Header(HEADER_WIDGET_SELECTOR),
  ],
  [
    PHOTO_CODE_WIDGET_NAME,
    new Photo(PHOTO_CODE_WIDGET_SELECTOR),
  ],
  [
    INSTAGRAM_WIDGET_NAME,
    new Instagram(INSTAGRAM_WIDGET_SELECTOR),
  ],
  [
    VIDEO_WIDGET_NAME,
    new Video(VIDEO_WIDGET_SELECTOR),
  ],
  [
    SLIDER_WIDGET_NAME,
    new Slider(SLIDER_WIDGET_SELECTOR),
  ],
  [
    TWITTER_WIDGET_NAME,
    new Twitter(TWITTER_WIDGET_SELECTOR),
  ],
  [
    SVG_LOADER_WIDGET_NAME,
    new SVGLoader(SVG_LOADER_WIDGET_SELECTOR),
  ],
  [
    FORM_WIDGET_NAME,
    new Form(FORM_WIDGET_SELECTOR),
  ],
  [
    BLOG_SOCIAL_WIDGET_NAME,
    new BlogSocialButtons(BLOG_SOCIAL_WIDGET_SELECTOR),
  ],
  [
    POST_IMAGE_NAME,
    new PostImage(POST_IMAGE_SELECTOR),
  ],
  [
    ITEMS_VIEW_NAME,
    new ItemsView(ITEMS_VIEW_SELECTOR),
  ],
  [
    ECOM_PRODUCT_NAME,
    new ProductsWidget(ECOM_PRODUCT_SELECTOR),
  ],
  [
    COOKIE_NOTICE_NAME,
    new CookieBanner(COOKIE_NOTICE_SELECTOR),
  ],
  [
    PROMOTION_NAME,
    new Promotion(PROMOTION_SELECTOR),
  ],
  [
    FACEBOOK_NAME,
    new Facebook(FACEBOOK_SELECTOR),
  ],
  [
    CART_BUTTON_NAME,
    new CartButton(CART_BUTTON_SELECTOR),
  ],
  [
    SOCIAL_BUTTON_NAME,
    new SocialButtons(SOCIAL_BUTTON_SELECTOR),
  ],
  [
    PINTEREST_BUTTON_NAME,
    new PinterestButton(PINTEREST_BUTTON_SELECTOR),
  ],
]);

const ENABLED_WIDGET_LIST = [
  SHARING_BUTTONS_WIDGET_NAME,
  GALLERY_WIDGET_NAME,
  EMBED_CODE_WIDGET_NAME,
  HEADER_WIDGET_NAME,
  PHOTO_CODE_WIDGET_NAME,
  INSTAGRAM_WIDGET_NAME,
  VIDEO_WIDGET_NAME,
  SLIDER_WIDGET_NAME,
  TWITTER_WIDGET_NAME,
  SVG_LOADER_WIDGET_NAME,
  FORM_WIDGET_NAME,
  ECOM_PRODUCT_NAME,
  COOKIE_NOTICE_NAME,
  BLOG_SOCIAL_WIDGET_NAME,
  POST_IMAGE_NAME,
  ITEMS_VIEW_NAME,
  PROMOTION_NAME,
  FACEBOOK_NAME,
  CART_BUTTON_NAME,
  SOCIAL_BUTTON_NAME,
  PINTEREST_BUTTON_NAME,
];

const connectWidgets = () => {
  const widgets = dom.getCollection('.js-widget');
  const store = dom.getElement('._store');

  if (store) {
    const EcommerceType = get(store, 'dataset.type', 'ECWID_CUSTOM_PAGE_TYPE');
    const router = connectRouter(EcommerceType);
    router.init();
  }

  if (isEmpty(widgets)) return;
  if (isFunction(createResizeObserver)) createResizeObserver();
  if (isFunction(createDeviceObserver)) createDeviceObserver();

  const widgetList = [...widgets].reduce((acc, el) => {
    const widgetName = el.dataset.widget;

    if (!widgetName
      || acc.indexOf(widgetName) !== -1
      || ENABLED_WIDGET_LIST.indexOf(widgetName) === -1) return acc;

    acc.push(widgetName);

    return acc;
  }, []);

  if (!widgetList.length) return;

  for (let i = 0; i < widgetList.length; i += 1) {
    const widget = widgetList[i];
    const init = WIDGETS_MAP.has(widget)
      ? WIDGETS_MAP.get(widget).init
      : () => null;

    init();
  }
};

imageErrorHandler();

const connectDynamic = async () => {
  const isTemplate = getStateValue('isTemplate', false);

  try {
    lazyLoad();
    setBodyColorClasses();

    if (!isTemplate) await initPremiumFeatures();
  } finally {
    if (!isTemplate) ieChangeCssVar();
    if (!isTemplate) BackToTop();
    connectWidgets();

    if (!isTemplate) await initAnalytics();

    handleAnchors();

    const resizeObserver = new ResizeObserver(debounce(() => {
      setBodyHasScroll();
    }, 200));
    resizeObserver.observe(dom.document.body);

    if (isTemplate) TemplatePreview();
  }
};

// This logic for IE
// ToDO: IE

const connectDynamicIE = () => {
  cssVars({
    onlyLegacy: false,
    silent: true,
    onComplete(cssText, styleNodes, cssVariables) {
      set(dom.window, ['cssVarPoly', 'varsStorage', 'varsList'], cssVariables);
      connectDynamic();
    },
  });
};

dom.on(dom.document, 'DOMContentLoaded', browser.isIe() ? connectDynamicIE : connectDynamic);
dom.on(dom.window, 'resize', () => {
  if (isFunction(initResizeObserver)) initResizeObserver();
  if (isFunction(initDeviceObserver)) initDeviceObserver();
});
