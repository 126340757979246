import ProviderWrapper from './Wrapper';
import dom from '../../../wrapper/DomWrapper';

import checkIsYouTubeThumbnailCorrect from '../utils';
import { MAX_YOUTUBE_THUMBNAIL } from '../constants';

class VideoYouTube extends ProviderWrapper {
  async init() {
    const {
      id,
      autoplay,
      fs,
      color,
      removeControls,
      isBackground,
      src,
    } = this.dataParams;
    const isExist = await this.checkIsVideoExist(id);

    this.renderVideoOnPage(isExist);

    if (!isExist) return;

    this.player = this.connectVideoDefault();
    this.setYouTubeThumbnail(src);
    this.addPreviewImage(this.player);

    this.player.source = {
      type: 'video',
      sources: [
        {
          src: id,
          provider: 'youtube',
        },
      ],
    };

    this.player.config.youtube = {
      autopause: 0,
      color,
      controls: removeControls ? 0 : 1,
      disablekb: 1,
      fs,
      iv_load_policy: 3,
      rel: 0,
      showinfo: 0,
      playlist: 0,
      resetOnEnd: 1,
      mute: isBackground ? 1 : autoplay,
      origin: dom.window.location.origin,
    };

    this.player.elements.poster.style.display = 'none';

    if (isBackground) {
      this.player.config.youtube.controls = 0;
      this.player.config.youtube.cc_load_policy = 0;
      this.player.config.youtube.fs = 0;
      this.player.config.youtube.iv_load_policy = 3;
      this.player.config.youtube.autoplay = 1;
      this.player.config.youtube.modestbranding = 1;
      this.player.config.youtube.playlist = 0;
    }
  }

  setYouTubeThumbnail = (src) => {
    if (!src || !src.includes(MAX_YOUTUBE_THUMBNAIL)) return;

    dom.updateStyle(this.video, { opacity: 0 });

    checkIsYouTubeThumbnailCorrect(src)
      .then((isCorrect) => {
        if (isCorrect) return;

        const hdThumbnail = src.replace(MAX_YOUTUBE_THUMBNAIL, '0');
        const elImage = dom.getElement('img', this.video);

        elImage.setAttribute('data-src', hdThumbnail);
        elImage.setAttribute('src', hdThumbnail);
      })
      .finally(() => dom.updateStyle(this.video, { opacity: 1 }));
  }

  checkIsVideoExist = async (id) => {
    if (!id) return Promise.resolve(false);

    const src = `https://img.youtube.com/vi/${id}/0.jpg`;

    const isExist = await checkIsYouTubeThumbnailCorrect(src);

    return isExist;
  }
}

export default VideoYouTube;
